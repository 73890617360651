@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

a {
    cursor: pointer
}

.timercolor {
    color: red !important;
}

.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}

.copyright-area {
    border: none;
}

.dataResponse {
    color: red;
}

.nav-title-bar {
    top: 10px;
}

.custom-title {
    height: 80px !important;
}

.mat-tab-label-active {
    color: blueviolet !important;
}

.mat-tab-label.mat-tab-disabled,
.mat-active-tab {
    color: black !important;
}

.mat-tab-header {
    border-bottom: none !important;
}

.mat-question-container {
    position: relative;
    left: 0;
    margin-top: 10px;
}

.mat-button-container {
    position: relative;
    left: 0;
}

.mat-tab-group {
    border-top: 2px solid #e7ecf1;
}

.main-logo-span {
    color: #2399c6;
    font-size: 30px
}

.breadcrumb-li {
    display: inline-block;
    margin-right: 5px;
}

.breadcrumb-a {
    margin: 0 5px 0 0;
}

#timerCountDown #minutes span {
    font-size: 24px;
    color: #f6da74;
    display: inline-block;
    text-align: center;
}

#timerCountDown .btn {
    margin-top: 0px !important;
}

.mat-tab-body-wrapper {
    padding-top: 10px;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-4 {
    margin-top: 32px;
}

.align-right {
    float: right;
    text-align: right;
}

.pr-0 {
    padding-right: 0;
}

.pl-0 {
    padding-left: 0;
}

.p-1 {
    padding: 8px !important;
}

.mr-n-1 {
    margin-right: -8px
}

.mt-4 {
    margin-top: 32px;
}

.clr-w {
    color: white;
}

.grid-anchor {
    padding: 0 10px 0 10px !important;
    cursor: pointer !important;
}

.min-width-150 {
    min-width: 150px !important;
}

.red-color {
    color: red !important;
}

.pageTitle {
    color: #2854f4;
    font-size: 26px;
    border-bottom: 1px solid #e9e9e9;
}

.page-breadcrumb {
    padding-left: 0 !important;
}

.addNewItem {
    float: right;
    font-size: 14px;
    cursor: pointer !important;
}

.header-style-two .navbar-wrap ul li a {
    padding: 30px 12px;
    // font-size: 13px;
}

.excel-download-btn {
    padding: 5px !important;
}

.excel-download-btn i {
    font-size: 30px;
}

.page-component-content {
    background-color: #ffffff !important;
    display: block !important;
    padding: 10px 10px 20px 20px !important;
}
#toast-container>div {
    width: 400px !important;    
}